// import authenticationRoutes from 'containers/Authentication/AuthenticationRoutes'
import authenticationRoutes from 'containers/AccountModule/AccountRoutes'
import dashboardRoutes from 'containers/DashboardModule/DashboardRoutes'
import React from 'react'
import { Redirect } from 'react-router-dom'
import logOutRoutes from 'containers/LogOutModule/LogOutRoutes'
import HomeRoutes from 'containers/HomeModule/HomeRoutes'
import CompanySummaryRoutes from 'containers/HomeModule/SystemAdmin/Company/CompanySummaryRoutes'

const StartRoute = [
  {
    props: { exact: true, path: '/' },
    component: <Redirect to="/" />,
  },
]

const errorRoute = [
  {
    // props: { exact: true, path: '/' },
    component: <Redirect to="/authentication/404" />,
  },
]

const routes = [
  ...HomeRoutes,
  ...CompanySummaryRoutes,
  ...dashboardRoutes,
  ...logOutRoutes,
  ...authenticationRoutes,
  ...StartRoute,
  ...errorRoute,
]

export default routes
